<template>
  <div id="index">
    <quiz :questions="q" :packs="p" :translate="t" />
    <foot :translate="t" />
  </div>
</template>

<script>
import quiz from "../components/Quiz";
import foot from "../components/Footer.vue";
import { questions } from "../data/questions";
import { packs } from "../data/packs";
import { translate } from "../data/translation";

export default {
  name: "Index",
  components: {
    quiz,
    foot,
  },
  props: {
    q: {
      type: Object,
      default: function () {
        switch (this.$route.name) {
          case "es":
            return questions.es;
          case "fr":
            return questions.fr;
          default:
            return questions.en;
        }
      },
    },
    p: {
      type: Object,
      default: function () {
        switch (this.$route.name) {
          case "es":
            return packs.es;
          case "fr":
            return packs.fr;
          default:
            return packs.en;
        }
      },
    },
    t: {
      type: Object,
      default: function () {
        switch (this.$route.name) {
          case "es":
            return translate.es;
          case "fr":
            return translate.fr;
          default:
            return translate.en;
        }
      },
    },
  },
  data() {
    return {
      props: {},
    };
  },
  computed: {},
};
</script>
