// Translation Objects
export const translate = {
    // English
    en: {
        complete: "Complete",
        prev: "PREV",
        next: "NEXT",
        findSolution: "CHECK YOUR GUT",
        shopNowURL: "https://www.teambeachbody.com/shop/us/b/nutrition-programs/4weekgutprotocol",
        shopNowBtn: "LEARN MORE",
        footer: "Beachbody, LLC. All rights reserved. Distributed by Beachbody, LLC, 3301 Exposition Blvd., Santa Monica, CA 90404. Beachbody, LLC is the owner of the Beachbody and Team Beachbody trademarks, and all related designs, trademarks, copyrights, and other intellectual property. All other trademarks are the property of their respective owners.",

    },

    // Spanish
    es: {
        complete: "Complete",
        prev: "ANTERIOR",
        next: "SIGUIENTE",
        findSolution: "CHECK YOUR GUT",
        shopNowURL: "https://www.teambeachbody.com/shop/us/b/nutrition-programs/4weekgutprotocol?locale=es_US",
        shopNowBtn: "MÁS INFORMACIÓN",
        footer: "Beachbody, LLC. Todos los derechos reservados. Distribuido en EE. UU. por Beachbody, LLC, 3301 Exposition Blvd., Santa Monica, CA 90404. Beachbody, LLC es propietario de las marcas comerciales Beachbody y Team Beachbody, y de todos los diseños, marcas comerciales, derechos de autor y otra propiedad intelectual relacionada. Todas las demás marcas comerciales son propiedad de sus respectivos dueños.",

    },

    // French
    fr: {
        complete: "Complete",
        prev: "précédent",
        next: "SUIVANT",
        findSolution: "CHECK YOUR GUT",
        shopNowURL: "https://www.teambeachbody.com/shop/ca/b/nutrition-programs/4weekgutprotocol?locale=fr_CA",
        shopNowBtn: "EN SAVOIR PLUS",
        footer: "Beachbody, LLC. Tous droits réservés. Distribué aux États-Unis par Beachbody, LLC, 3301 Exposition Blvd., Santa Monica, CA 90404. Beachbody, LLC est le propriétaire des marques de commerce Beachbody et Team Beachbody, et de tous les modèles, marques de commerce, droits d’auteur et autres propriétés intellectuelles connexes. Toutes les autres marques de commerce appartiennent à leurs propriétaires respectifs.",

    },

};