// Question Object
export const questions = {
    // English
    en: [
        {
            index: 0,
            score: "",
            text: "Do you eat a wide variety of fruits, vegetables, grains, beans, and nuts on a daily basis?",
            responses: [
                {
                    text: "Yes",
                    value: "0"
                },
                {
                    text: "No",
                    value: "1"
                }
            ]
        },
        {
            index: 1,
            score: "",
            text: "Do you drink at least half an ounce of water for each pound you weigh, each day?",
            responses: [
                {
                    text: "Yes",
                    value: "0"
                },
                {
                    text: "No",
                    value: "1"
                }
            ]
        },
        {
            index: 2,
            score: "",
            text: "Do you experience excessive burping or foul smelling gas?",
            responses: [
                {
                    text: "Yes",
                    value: "1"
                },
                {
                    text: "No",
                    value: "0"
                }
            ]
        },
        {
            index: 3,
            score: "",
            text: "Do you frequently experience indigestion, bloating, cramping, or discomfort after eating?",
            responses: [
                {
                    text: "Yes",
                    value: "1"
                },
                {
                    text: "No",
                    value: "0"
                }
            ]
        },
        {
            index: 4,
            score: "",
            text: "Are your bowel movements irregular, difficult, incomplete or occasionally loose (diarrhea)?",
            responses: [
                {
                    text: "Yes",
                    value: "1"
                },
                {
                    text: "No",
                    value: "0"
                }
            ]
        },
        {
            index: 5,
            score: "",
            text: "Do you often crave sugar?",
            responses: [
                {
                    text: "Yes",
                    value: "1"
                },
                {
                    text: "No",
                    value: "0"
                }
            ]
        },
        {
            index: 6,
            score: "",
            text: "Do you often experience energy slumps during the day, especially after meals? ",
            responses: [
                {
                    text: "Yes",
                    value: "1"
                },
                {
                    text: "No",
                    value: "0"
                }
            ]
        },
        {
            index: 7,
            score: "",
            text: "Do you have difficulty losing or gaining weight despite proper nutrition & exercising regularly?",
            responses: [
                {
                    text: "Yes",
                    value: "1"
                },
                {
                    text: "No",
                    value: "0"
                }
            ]
        },
        {
            index: 8,
            score: "",
            text: "Do you experience mood swings?",
            responses: [
                {
                    text: "Yes",
                    value: "1"
                },
                {
                    text: "No",
                    value: "0"
                }
            ]
        },
        {
            index: 9,
            score: "",
            text: "Do you ever have issues with your complexion?",
            responses: [
                {
                    text: "Yes",
                    value: "1"
                },
                {
                    text: "No",
                    value: "0"
                }
            ]
        },
    ],
    // Spanish
    es: [
        {
            index: 0,
            score: "",
            text: "¿Comes una buena variedad de frutas, vegetales, granos, legumbres y frutos secos todos los días? ",
            responses: [
                {
                    text: "Sí",
                    value: "0"
                },
                {
                    text: "No",
                    value: "1"
                }
            ]
        },
        {
            index: 1,
            score: "",
            text: "¿Bebes al menos media onza de agua por cada libra que pesas todos los días?",
            responses: [
                {
                    text: "Sí",
                    value: "0"
                },
                {
                    text: "No",
                    value: "1"
                }
            ]
        },
        {
            index: 2,
            score: "",
            text: "¿Eructas o tienes gases con mal olor con frecuencia?",
            responses: [
                {
                    text: "Sí",
                    value: "1"
                },
                {
                    text: "No",
                    value: "0"
                }
            ]
        },
        {
            index: 3,
            score: "",
            text: "¿Sueles tener indigestión, hinchazón, calambres o malestar después de comer?",
            responses: [
                {
                    text: "Sí",
                    value: "1"
                },
                {
                    text: "No",
                    value: "0"
                }
            ]
        },
        {
            index: 4,
            score: "",
            text: "¿Tus movimientos intestinales son irregulares, difíciles, incompletos o flojos en ocasiones (diarrea)?",
            responses: [
                {
                    text: "Sí",
                    value: "1"
                },
                {
                    text: "No",
                    value: "0"
                }
            ]
        },
        {
            index: 5,
            score: "",
            text: "¿Sueles tener antojos de azúcar? ",
            responses: [
                {
                    text: "Sí",
                    value: "1"
                },
                {
                    text: "No",
                    value: "0"
                }
            ]
        },
        {
            index: 6,
            score: "",
            text: "¿Sueles tener bajones de energía durante el día, en especial, después de las comidas?",
            responses: [
                {
                    text: "Sí",
                    value: "1"
                },
                {
                    text: "No",
                    value: "0"
                }
            ]
        },
        {
            index: 7,
            score: "",
            text: "¿Te cuesta ganar o perder peso aunque lleves una buena alimentación y hagas ejercicio?",
            responses: [
                {
                    text: "Sí",
                    value: "1"
                },
                {
                    text: "No",
                    value: "0"
                }
            ]
        },
        {
            index: 8,
            score: "",
            text: "¿Sueles tener altibajos emocionales? ",
            responses: [
                {
                    text: "Sí",
                    value: "1"
                },
                {
                    text: "No",
                    value: "0"
                }
            ]
        },
        {
            index: 9,
            score: "",
            text: "¿A veces tienes problemas en la piel? ",
            responses: [
                {
                    text: "Sí",
                    value: "0"
                },
                {
                    text: "No",
                    value: "1"
                }
            ]
        },
    ],
    fr: [
        {
            index: 0,
            score: "",
            text: "Consommez-vous chaque jour une grande variété de fruits, de légumes, de céréales, de haricots et de noix ? ",
            responses: [
                {
                    text: "Oui",
                    value: "1"
                },
                {
                    text: "Non",
                    value: "0"
                }
            ]
        },
        {
            index: 1,
            score: "",
            text: "Buvez-vous chaque jour au moins 30 ml d'eau pour chaque kilo que vous pesez ?",
            responses: [
                {
                    text: "Oui",
                    value: "0"
                },
                {
                    text: "Non",
                    value: "1"
                }
            ]
        },
        {
            index: 2,
            score: "",
            text: "Souffrez-vous de rôts ou de gaz malodorantes trop fréquents ?",
            responses: [
                {
                    text: "Oui",
                    value: "1"
                },
                {
                    text: "Non",
                    value: "0"
                }
            ]
        },
        {
            index: 3,
            score: "",
            text: "Souffrez-vous fréquemment d'indigestion, de ballonnement, de crampes ou de gêne après avoir mangé ? ",
            responses: [
                {
                    text: "Oui",
                    value: "1"
                },
                {
                    text: "Non",
                    value: "0"
                }
            ]
        },
        {
            index: 4,
            score: "",
            text: "Votre transit intestinal est-il irrégulier, difficile, incomplet ou parfois mou (diarrhée) ? ",
            responses: [
                {
                    text: "Oui",
                    value: "1"
                },
                {
                    text: "Non",
                    value: "0"
                }
            ]
        },
        {
            index: 5,
            score: "",
            text: "Avez-vous souvent envie de sucre ?",
            responses: [
                {
                    text: "Oui",
                    value: "1"
                },
                {
                    text: "Non",
                    value: "0"
                }
            ]
        },
        {
            index: 6,
            score: "",
            text: "Ressentez-vous souvent des chutes d'énergie pendant la journée, en particulier après les repas ?",
            responses: [
                {
                    text: "Oui",
                    value: "1"
                },
                {
                    text: "Non",
                    value: "0"
                }
            ]
        },
        {
            index: 7,
            score: "",
            text: "Avez-vous du mal à perdre ou à gagner du poids malgré une nutrition appropriée et une activité régulière ?",
            responses: [
                {
                    text: "Oui",
                    value: "1"
                },
                {
                    text: "Non",
                    value: "0"
                }
            ]
        },
        {
            index: 8,
            score: "",
            text: "Souffrez-vous de sautes d'humeur ?",
            responses: [
                {
                    text: "Oui",
                    value: "1"
                },
                {
                    text: "Non",
                    value: "0"
                }
            ]
        },
        {
            index: 9,
            score: "",
            text: "Avez-vous déjà rencontré des problèmes avec votre teint ? ",
            responses: [
                {
                    text: "Oui",
                    value: "0"
                },
                {
                    text: "Non",
                    value: "1"
                }
            ]
        },
    ],

};