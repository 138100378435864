<template>
  <div id="quiz">
    <div v-show="questionIndex < questions.length">
      <hero />
      <v-row>
        <v-progress-linear
          :value="questionIndex * (100 / questions.length)"
          height="35"
          color="primary"
          background-color="secondary"
          class="text-body-2"
        >
          <span v-show="questionIndex > 0" class="text-body-2 black--text">
            {{ ((questionIndex / questions.length) * 100).toFixed(0) }}
            <sup>%&nbsp;</sup>
            <span v-html="translate.complete"></span>
          </span>
        </v-progress-linear>
      </v-row>
    </div>

    <div
      v-for="(question, id) in questions"
      :key="question[id]"
      justify="center"
    >
      <v-container v-show="id === questionIndex" class="maxWidth">
        <!-- QUESTIONS -->
        <v-row class="my-3 my-md-7 px-3 px-lg-6">
          <v-col>
            <h1
              class="text-center text-h3 text-md-h2"
              v-html="question.text"
            ></h1>
          </v-col>
        </v-row>

        <!-- RESPONSES -->
        <v-item-group v-model="question.score" :name="question.name">
          <v-row>
            <v-col
              v-for="(response, id) in question.responses"
              :key="id"
              cols="12"
              md="6"
            >
              <v-item
                v-slot:default="{ active, toggle }"
                :value="response.value"
              >
                <v-card
                  :color="active ? 'success' : ''"
                  :class="vCardClass"
                  height="60"
                  @click="toggle"
                  tile
                >
                  <div :class="responseText" v-html="response.text"></div>
                </v-card>
              </v-item>
            </v-col>
          </v-row>
        </v-item-group>

        <!-- QUIZ BUTTONS  -->
        <v-row class="mt-3 mt-md-6">
          <v-col xs="0" sm="3">&nbsp;</v-col>
          <v-col xs="6" sm="3">
            <v-btn
              v-if="questionIndex > 0"
              @click="prev"
              large
              rounded
              block
              right
              color="grey darken-4"
              :class="buttonText"
              depressed
            >
              <span class="body-2 white--text" v-html="translate.prev"></span>
            </v-btn>
          </v-col>
          <v-col xs="6" sm="3">
            <v-btn
              @click="next(question.text, questionIndex)"
              :disabled="question.score == '' || question.score == null"
              color="grey darken-4"
              :class="buttonText"
              large
              rounded
              block
              left
              depressed
              ><span class="body-2 white--text" v-html="translate.next"></span>
            </v-btn>
          </v-col>
          <v-col xs="0" sm="3">&nbsp;</v-col>
        </v-row>
      </v-container>
    </div>

    <!--  RESULTS || RESULTS || RESULTS -->

    <!-- Add the line below to div to reveal upon quiz completion -->
    <!-- v-show="questionIndex === questions.length" -->

    <div
      id="results"
      class="px-0 pt-3 pt-sm-6 pt-md-9" v-show="questionIndex === questions.length"
    >
      <!-- Results Hero -->
      <v-container class="maxWidth">
        <v-img
          :src="require('@/assets/heros/hero-results-xl.jpg')"
          class="rounded-lg"
        >
        </v-img>
      </v-container>

      <v-container class="maxWidth">
        <div class="px-3 px-md-6">
          <p
            class="text-body-1 text-md-subtitle-1 mt-3"
            v-html="packScore.desc"
          ></p>
        </div>
        <v-row class="mt-3 mt-sm-6 mt-md-9">
          <v-col xs="0" sm="4"></v-col>
          <v-col xs="12" sm="4">
            <v-btn
              v-html="translate.shopNowBtn"
              :href="translate.shopNowURL + referral"
              large
              rounded
              block
              color="grey darken-4"
              :class="buttonText"
              depressed
              target="_blank"
            ></v-btn>
          </v-col>
          <v-col xs="0" sm="4"></v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import hero from "../components/Hero";

export default {
  name: "quiz",
  components: {
    hero,
  },
  props: {
    questions: {
      type: Object,
      default: function () {
        return "";
      },
    },
    translate: {
      type: Object,
      default: function () {
        return "";
      },
    },
    packs: {
      type: Array,
    },
    customer: {
      type: String,
    },
    referral: {
      default: function() {
        var str = window.location.href;
        var objURL = {};
        str.replace(
          new RegExp( "([^?=&]+)(=([^&]*))?", "g" ),
          function( $0, $1, $2, $3 ){
              objURL[ $1 ] = $3;
          }
        );
        switch (objURL.referringRepID) {
          case undefined:
            return '';
          case '':
            return '';
          default:
            return '?referringRepID=' + objURL.referringRepID;
        }
      }
    }
  },
  data() {
    return {
      questionIndex: 0,
      vCardClass: "d-flex align-center px-3",
      ctaClass: "text-subtitle-h2 font-weight-bold py-6",
      responseText: "flex-grow-1 text-center text-subtitle-2 px-2",
      buttonText: "text-body-2 font-weight-medium px-9 white--text",
      whiteCenterTxt: "grey--text text--darken-4 text-center",
    };
  },
  methods: {
    toggle: function toggleProduct1() {},
    // Go to previous question
    prev() {
      this.questionIndex--;
    },
    // Go to next question
    next(question, question_number) {
      this.questionIndex++;
      this.$gtag.event("quiz_progression", {
        event_category: "engagement",
        event_label: "",
        question: question,
        questions_completed:
          "Completed " + (1 + question_number) + " of " + this.questions.length,
      });
      if (this.questionIndex === this.questions.length) {
        this.$gtag.event("quiz_progression", {
          event_category: "engagement",
          event_label: "",
          choice: this.packScore.name,
        });
      }
    },
  },
  computed: {
    packScore() {
      const p = this.packs;
      const q = this.questions;
      const q1 = Number(q[0].score);
      const q2 = Number(q[1].score);
      const q3 = Number(q[2].score);
      const q4 = Number(q[3].score);
      const q5 = Number(q[4].score);
      const q6 = Number(q[5].score);
      const q7 = Number(q[6].score);
      const q8 = Number(q[7].score);
      const q9 = Number(q[8].score);
      const q10 = Number(q[9].score);
      const ps = q1 + q2 + q3 + q4 + q5 + q6 + q7 + q8 + q9 + q10;
      if (ps >= 0 && ps <= 3) return p[0];
      if (ps >= 4 && ps <= 6) return p[1];
      else return p[2];
    },
  },
  watch: {
    returnResults() {
      if (this.questionIndex === this.questions.length) {
        return this.$gtag.event("quiz_progression", {
          event_category: "engagement",
          event_label: "",
          choice: this.packScore.name,
        });
      }
    },
    $route(to, from) {
      if (to !== from) {
        location.reload();
      }
    },
  },
};
</script>

<style lang="scss">
.maxWidth {
  max-width: 960px;
}

fieldset {
  border: none !important;
}

.v-text-field__slot label {
  font-style: italic !important;
  color: #bdbdbd !important;
}

.v-messages__message {
  font-style: italic !important;
  color: #bdbdbd !important;
}

sup {
  display: inline;
}
</style>