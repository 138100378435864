<template>
  <div class="mx-auto mt-6 mt-md-12 mb-6" style="max-width: 960px">
    <v-footer class="py-6 px-9 px-md-3" style="background: inherit" padless>
      <p class="caption text-justify">
        &copy; {{ new Date().getFullYear() }} {{ translate.footer }}
      </p>
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "foot",
  components: {},
  props: {
    translate: {
      type: Object,
      default: function () {
        return "";
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
