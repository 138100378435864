// Packs Object
export const packs = {
    // English
    en: [
        {
            index: 0,
            score: "0-3",
            name: "Question 1",
            desc: "Your responses indicate that you are well on your way to a healthy gut! But you may be surprised how good it feels to follow a program for 4 weeks that brings it all together. Many people learn they may have food sensitivities and gut issues they weren’t even aware of. Poor digestion, energy levels, sleep, skin, and even mood can be a sign that your gut microbiome may be out of balance. The 4 Week Gut Protocol can help you identify foods that might be upsetting your gut, and help you rebalance your diet and lifestyle to have a long-term, positive impact on your overall health. Imagine how good it would feel to truly understand what your body needs to thrive!",
            url: "#"
        },
        {
            index: 1,
            score: "4-6",
            name: "Question 2",
            desc: "Your responses indicate that you may be sensitive to certain foods. We recommend trying The 4 Week Gut Protocol, a step-by-step guide to discovering how foods impact not just your gut health, but your overall health. Everything you eat, drink, and think each day can alter your body’s ability to feel its best. Nutrition expert Autumn Calabrese will show you how your diet and lifestyle may be impacting your gut microbiome. You’ll learn to focus on quality, nutrient-dense foods and how to include prebiotics and probiotics to help give your gut the extra TLC it needs. With just a few lifestyle changes to help support your overall gut health, you really can feel great again!",
            url: "#"
        },
        {
            index: 2,
            score: "7-10",
            name: "Question 1",
            desc: "Your responses indicate that your nutrition habits could be affecting your overall health, which may mean your gut microbiome is out of balance. We strongly recommend that you do The 4 Week Gut Protocol, a comprehensive nutrition program that helps you reset and rebalance your gut health. Far too many of us have been let down by restrictive diets and confusing messages that leave us feeling worse, not better. But The 4 Week Gut Protocol helps you isolate and identify the foods that can cause digestive sensitivities and can affect everything from your sleep to your energy levels, and even your mood. It’s not a quick fix—it’s a whole new approach to helping optimize your health by understanding YOUR unique body. We also recommend consulting a doctor to make sure your symptoms don’t indicate anything more serious.",
            url: "#"
        },
    ],
    // US Spanish
    es: [
        {
            index: 0,
            score: "0-3",
            name: "Question 1",
            desc: "¡Tus respuestas indican que vas por buen camino con tu salud intestinal! Aun así, podría sorprenderte lo bien que se siente seguir un programa de cuatro semanas que lo reúne todo sobre el tema. Muchas personas descubren que tienen sensibilidades alimentarias y problemas intestinales de los que no eran conscientes. Los problemas con la digestión, los niveles de energía, el descanso, el cutis e incluso el estado de ánimo pueden ser signos de que tu microbioma intestinal podría estar desequilibrado. The 4 Week Gut Protocol te ayuda a identificar alimentos que podrían estar afectando tu intestino, y a reequilibrar tu alimentación y tu estilo de vida para lograr un impacto positivo a largo plazo en tu salud en general. ¡Imagina lo bien que se sentiría comprender realmente qué necesita tu cuerpo para funcionar de la mejor manera!",
            url: "#"
        },
        {
            index: 1,
            score: "4-6",
            name: "Question 2",
            desc: "Tus respuestas indican que podrías tener sensibilidad a ciertos alimentos. Te recomendamos que pruebes The 4 Week Gut Protocol, una guía paso a paso que te ayuda a descubrir el impacto que tiene lo que comes no solo en tu salud intestinal, sino también en tu salud en general. Todo lo que comes, bebes y piensas día a día puede alterar la capacidad de tu cuerpo de sentirse bien. La experta en nutrición Autumn Calabrese te mostrará el impacto que podrían estar teniendo tu alimentación y tu estilo de vida en tu microbioma intestinal. Aprenderás a enfocarte en alimentos de calidad y densos en nutrientes, y a incluir prebióticos y probióticos para ayudar a darle a tu intestino el cuidado adicional que necesita. Con solo unos cambios en tu estilo de vida que ayuden a mejorar tu salud intestinal general, ¡realmente podrías sentirte estupendamente otra vez! ",
            url: "#"
        },
        {
            index: 2,
            score: "7-10",
            name: "Question 3",
            desc: "Tus respuestas indican que tus hábitos alimentarios podrían estar afectando tu salud en general, lo que podría significar que tu microbioma intestinal está desequilibrado. Te recomendamos que hagas The 4 Week Gut Protocol, un programa de nutrición integral que te ayuda a restablecer y reequilibrar tu salud intestinal. A muchas personas nos han decepcionado las dietas restrictivas y los mensajes confusos que terminan haciéndonos sentir peor, no mejor. Pero The 4 Week Gut Protocol te ayuda a aislar e identificar las comidas que podrían generarte sensibilidades digestivas y afectar todo, desde tu descanso hasta tus niveles de energía, e incluso tu estado de ánimo. No es una solución instantánea, sino un enfoque completamente nuevo que te ayuda a optimizar tu salud al comprender TU cuerpo único. También te recomendamos que consultes a un médico para asegurarte de que tus síntomas no se deban a algo más grave.",
            url: "#"
        },
    ],
    // France
    fr: [
        {
            index: 0,
            score: "0-3",
            name: "Question 1",
            desc: "Vos réponses indiquent que vous êtes sur le chemin vers une bonne santé intestinale ! Vous pourriez tout de même être surpris des bienfaits que pourrez vous apporter un programme de 4 semaines. Beaucoup de personnes se rendent compte qu’elles sont sensibles à certains aliments et découvrent des problèmes intestinaux qu’elles ignoraient. Mauvaise digestion, niveaux d’énergie bas, sommeil de mauvaise qualité, peau terne et même mauvaise humeur peuvent indiquer un déséquilibre du microbiome intestinal. Le programme The 4 Week Gut Protocol peut vous aider à identifier les aliments susceptibles de troubler vos intestins et à rééquilibrer votre régime alimentaire et votre style de vie pour un impact positif à long terme sur votre santé générale. Imaginez comme il serait bon de comprendre réellement ce dont votre corps a besoin pour s’épanouir !",
            url: "#"
        },
        {
            index: 1,
            score: "4-6",
            name: "Question 2",
            desc: "Vos réponses indiquent que vous pouvez être sensible à certains aliments. Nous vous recommandons d’essayer The 4 Week Gut Protocol, un guide pas à pas permettant de découvrir la manière dont les aliments affectent non seulement notre santé intestinale, mais aussi notre santé en général. Tout ce que vous mangez, buvez et pensez chaque jour peut aider votre corps à se sentir au top. L’experte en nutrition Autumn Calabrese vous montrera comment votre régime alimentaire et votre style de vie peuvent affecter votre microbiome intestinal. Vous apprendrez à donner la priorité aux aliments de qualité, riches en nutriments, et à inclure des prébiotiques et des probiotiques pour vous aider à apporter à vos intestins l’attention particulière dont ils ont besoin. De simples changements dans votre style de vie peuvent vous aider à favoriser votre santé intestinale pour vous sentir bien à nouveau !",
            url: "#"
        },
        {
            index: 2,
            score: "7-10",
            name: "Question 3",
            desc: "Vos réponses indiquent que vos habitudes nutritionnelles pourraient affecter votre santé générale, ce qui peut indiquer que votre microbiome intestinal est déséquilibré. Nous vous recommandons fortement de suivre The 4 Week Gut Protocol, un programme de nutrition complet qui vous aide à réinitialiser et à rééquilibrer votre santé intestinale. Beaucoup trop d’entre nous ont été déçus par des régimes restrictifs et des messages déroutants qui, nous font nous sentir encore moins bien, et non mieux. Le programme The 4 Week Gut Protocol vous aide lui à isoler et à identifier les aliments pouvant provoquer des sensibilités digestives et avoir un impact sur toute votre vie, de votre sommeil à vos niveaux d’énergie, en passant même par votre humeur. Il ne s’agit pas d’une solution rapide, mais d’une toute nouvelle approche pour aider à optimiser votre santé en comprenant VOTRE corps qui est unique. Nous vous recommandons également de consulter un médecin pour vous assurer que vos symptômes n’indiquent rien de plus grave.",
            url: "#"
        },
    ],
};

